import {defineStore} from 'pinia'
import {
    useGlobalEvent,
    useLoggedIdUserDataPreparationEvent, useNewFeedPostToastEvent,
    useNotificationEvent,
    useSignupDataSubmitEvent
} from "~/composables/useEventBus";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import {NetworkService} from "~/utils/NetworkService";
import type {PostOnAContestRb} from "~/models/request/postOnAContestRb";
import type {GetActivePostsRb} from "~/models/request/getActivePostsRb";
import type {FeedPost} from "~/models/FeedPost";
import type {GetAllPostsRb} from "~/models/request/getAllPostsRb";
import type {UpdatePostRb} from "~/models/request/updatePostRb";
import type {LikeFeedPostRb} from "~/models/request/likeFeedPostRb";
import type {FeedPostLike} from "~/models/feedPostLike";
import {FeedPostLikeStatus} from "~/utils/enums/FeedPostLikeStatus";
import {FeedPostStatus} from "~/utils/enums/FeedPostStatus";
import type {AddUpdateContestRb} from "~/models/request/addUpdateContestRb";
import type {Contest} from "~/models/contest";
import {clubStore} from "~/store/clubStore";
import type {ContestPosition} from "~/models/contestPosition";
import type {GetFeedPostsByCountOrderRb} from "~/models/request/getFeedPostsByCountOrderRb";
import type {GetTopPostsByRankRb} from "~/models/request/getTopPostsByRankRb";
import type {AddUpdateCommentRb} from "~/models/request/addUpdateCommentRb";
import {ReactionType} from "~/utils/enums/ReactionType";
import {ContestStatusLocal} from "~/utils/enums/ContestStatusLocal";
import type {GetMyLikedPostsRb} from "~/models/request/GetMyLikedPostsRb";
import type {LoadMyPostsForContestRb} from "~/models/request/LoadMyPostsForContestRb";
import {userStore} from "~/store/userStore";

export const contestStore = defineStore({
    id: 'contest-store',
    persist: true,
    state: () => {
        return {
            activePostsOfCurrentEvent: <FeedPost[]>[],
            allPostsOfCurrentEvent: <FeedPost[]>[],
            currentContestPositions: <ContestPosition[]>[],
            pendingPostOfContestEvent: <FeedPost[]>[],
            topPostsOfContestEvent: <FeedPost[]>[],
            myLikedPostsOfContestEvent: <FeedPost[]>[],
            myPostsForContestEvent: <FeedPost[]>[],
            currentContest: <Contest>{},
            currentContestStatus: <ContestStatusLocal>{},
            feedPostCount: <number>0,
            totalFeedPostCount: <number>0,
            voteCount: <number>0,
            views: <number>0
        }
    },
    actions: {
        deletePostFromList: function (id: number, contestId: number | null = null) {
            this.activePostsOfCurrentEvent = this.activePostsOfCurrentEvent.filter((item: FeedPost) => item.id != id);
            this.myPostsForContestEvent = this.myPostsForContestEvent.filter((item: FeedPost) => item.id != id);
            this.removePendingPost(id);
            this.addUpdatePostCount(false, true);
            if(contestId) this.getTotalCountOfPostsAndLikes(contestId);
        },

        addPostToList: function (post: FeedPost, withNotify: boolean = false, addToTheTop:boolean = false, removeFromActiveListFirst:boolean=true) {
            const useUserStore = userStore()
            this.removePendingPost(post.id, removeFromActiveListFirst);
            let index = this.activePostsOfCurrentEvent.findIndex((item: FeedPost) => item.id == post.id);
            const isMyPost = post.UserId==useUserStore.getLoggedInUser.id;
            if (index < 0) {
                this.addUpdatePostCount(true, false);
                if(addToTheTop){
                    this.activePostsOfCurrentEvent = [post, ...this.activePostsOfCurrentEvent];
                    if(isMyPost){
                        this.myPostsForContestEvent = [post, ...this.myPostsForContestEvent];
                    }
                }else{
                    this.activePostsOfCurrentEvent = [...this.activePostsOfCurrentEvent, post];
                    if(isMyPost){
                        this.myPostsForContestEvent = [...this.myPostsForContestEvent, post];
                    }
                }
                if (withNotify) {
                    // useGlobalEvent(useNewFeedPostToastEvent());
                }
            }
        },
        addToAllPostList: function (post: FeedPost, withNotify: boolean = false) {
            let indexOfAllPost = this.allPostsOfCurrentEvent.findIndex((item: FeedPost) => item.id == post.id);
            if (indexOfAllPost < 0) {
                this.allPostsOfCurrentEvent = [post, ...this.allPostsOfCurrentEvent];
            }
        },
        clearContestStore: function () {
            this.clearActivePostsOfCurrentEvent();
            this.clearAllPostsOfCurrentEvent();
            this.clearContestPositions();
            this.clearPendingPostsOfCurrentEvent();
            this.clearTopPosts();
            this.clearMyLikedPosts();
            this.clearMyPosts();
        },
        clearActivePostsOfCurrentEvent: function () {
            this.activePostsOfCurrentEvent = [];
        },
        clearAllPostsOfCurrentEvent: function () {
            this.allPostsOfCurrentEvent = [];
        },
        clearPendingPostsOfCurrentEvent: function () {
            this.pendingPostOfContestEvent = [];
        },
        clearContestPositions: function () {
            this.currentContestPositions = [];
        },
        clearTopPosts: function () {
            this.topPostsOfContestEvent = [];
        },
        clearMyLikedPosts: function () {
            this.myLikedPostsOfContestEvent = [];
        },
        clearMyPosts: function () {
            this.myPostsForContestEvent = [];
        },
        postOnAContest: async function (postOnAContestRb: PostOnAContestRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Contest/addUpdatePost';
            const ns = new NetworkService();
            let {
                Result,
                IsOperationSuccessful
            } = await ns.post$fetch(url, postOnAContestRb, null, "postOnAContest" + new Date().getMilliseconds() + "_")
            if (IsOperationSuccessful) {
                return Result;
            } else {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: Result,
                })
                return null;
            }
        },
        loadActivePosts: async function (getActivePostsRb: GetActivePostsRb, fromServer:boolean = false) {
            try {
                const config = useRuntimeConfig()
                let url = fromServer?'/api/contest/get-active-posts-by-contest-id':(config.public.BASE_URL_SOPS + '/api/Contest/getActivePostsByContestId');
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, getActivePostsRb, null, "getActivePosts" + new Date().getMilliseconds() + "_", false, fromServer)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadActivePostsByCountOrder: async function (getFeedPostsByCountOrderRb: GetFeedPostsByCountOrderRb, fromServer:boolean = false) {
            try {
                const config = useRuntimeConfig()
                let url = fromServer?'/api/contest/get-active-posts-by-count-order':(config.public.BASE_URL_SOPS + '/api/Contest/getPostsByCountOrder');
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, getFeedPostsByCountOrderRb, null, "loadActivePostsByCountOrder" + new Date().getMilliseconds() + "_", false, fromServer)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadAllPosts: async function (getAllPostsRb: GetAllPostsRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/getPostsByContestId';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, getAllPostsRb, null, "loadAllPosts" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadMyLikedPosts: async function (getMyLikedPostsRb: GetMyLikedPostsRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/getLikedPostsOfUser';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, getMyLikedPostsRb, null, "loadMyLikedPosts" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadMyPostsForContest: async function (loadMyPostsForContestRb: LoadMyPostsForContestRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/getFeedPostsByContestIdAndUserId';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, loadMyPostsForContestRb, null, "loadMyPostsForContest" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        updateAPost: async function (updatePostRb: UpdatePostRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/addUpdatePost';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, updatePostRb, null, "updateAPost" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        likeAPost: async function (likeFeedPostRb: LikeFeedPostRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/likeFeedPost';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, likeFeedPostRb, null, "likeAPost" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        addOrUpdateAContest: async function (addUpdateContestRb: AddUpdateContestRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/addUpdateContest';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, addUpdateContestRb, null, "addOrUpdateAContest" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadContestPositions: async function (contestId: number) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/getPositionsByContestId';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {contest_id: contestId}, null, "addOrUpdateAContest" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadTopPostsByRank: async function (getTopPostsByRankRb: GetTopPostsByRankRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/getTopPostsByRank';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, getTopPostsByRankRb, null, "getTopPostsByRank" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadAndSetActivePostsOfCurrentEvent: async function (getActivePostsRb: GetActivePostsRb, byCountOrder: boolean, withClearPreviousData: boolean = true, fromServer:boolean = false) {
            try {
                let response;
                if (byCountOrder) {
                    response = await this.loadActivePostsByCountOrder(<GetFeedPostsByCountOrderRb>{
                        contest_id: getActivePostsRb.contest_id,
                        limit: getActivePostsRb.limit,
                        offset: getActivePostsRb.offset,
                        UserId: getActivePostsRb.UserId
                    }, fromServer)
                } else {
                    response = await this.loadActivePosts(getActivePostsRb, fromServer)
                }
                if (response) {
                    if (withClearPreviousData) {
                        this.clearActivePostsOfCurrentEvent();
                    }
                    for (const post of response ?? []) {
                        const feedPost = post as FeedPost;
                        if (!withClearPreviousData) {
                            const foundItem = this.activePostsOfCurrentEvent.find((item) => item.id == feedPost.id);
                            if (!foundItem) {
                                this.activePostsOfCurrentEvent.push(feedPost);
                            }
                        } else {
                            this.activePostsOfCurrentEvent.push(feedPost);
                        }
                    }
                }
                this.checkForPendingPosts();
                return (response??[]).length;
            } catch (e) {
                console.log(e)
            }
        },
        loadAndSetAllPostsOfCurrentEvent: async function (getAllPostsRb: GetAllPostsRb, withClearPreviousData: boolean = true) {
            try {
                const response = await this.loadAllPosts(getAllPostsRb);
                if (response) {
                    if (withClearPreviousData) {
                        this.clearAllPostsOfCurrentEvent();
                    }
                    for (const post of response) {
                        const feedPost = post as FeedPost;
                        if (!withClearPreviousData) {
                            const foundItem = this.allPostsOfCurrentEvent.find((item) => item.id == feedPost.id);
                            if (!foundItem) {
                                this.allPostsOfCurrentEvent.push(feedPost);
                            }
                        } else {
                            this.allPostsOfCurrentEvent.push(feedPost);
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        postUpdateByAdmin: async function (updatePostRb: UpdatePostRb) {
            const response = await this.updateAPost(updatePostRb);
            if (response) {
                const updatedPost = response as FeedPost;
                const i = this.allPostsOfCurrentEvent.findIndex((item) => item.id == updatePostRb.post_id);
                this.allPostsOfCurrentEvent[i] = updatedPost;
                return updatedPost;
            }
        },
        likeAPostAndUpdateLikeInfo: async function (likeFeedPostRb: LikeFeedPostRb) {
            const response = await this.likeAPost(likeFeedPostRb);
            if (response) {
                const feedPostLike = response as FeedPostLike;
                const foundPost = this.activePostsOfCurrentEvent.find((item) => item.id == likeFeedPostRb.feed_post_id);
                const foundLikedPost = this.myLikedPostsOfContestEvent.find((item) => item.id == likeFeedPostRb.feed_post_id);
                const foundMyPost = this.myPostsForContestEvent.find((item) => item.id == likeFeedPostRb.feed_post_id);
                // For active posts
                if (foundPost) {
                    if(likeFeedPostRb.Status == FeedPostLikeStatus.ACTIVE){
                        if(!foundPost.Likes){
                            foundPost.Likes = [];
                        }
                        if(!foundPost.Reactions){
                            foundPost.Reactions = [];
                        }
                        if(likeFeedPostRb.Type==ReactionType.VOTE){
                            foundPost?.Likes.push(feedPostLike);
                        }else{
                            foundPost.Reactions = foundPost.Reactions.filter((item)=>item.id!=likeFeedPostRb.like_id);
                            foundPost?.Reactions.push(feedPostLike);
                        }
                        foundPost.Likes = Array.from(new Map(foundPost?.Likes.map(obj => [obj.id, obj])).values());
                        foundPost.Reactions = Array.from(new Map(foundPost?.Reactions.map(obj => [obj.id, obj])).values());
                    } else {
                        if(likeFeedPostRb.Type==ReactionType.VOTE){
                            foundPost.Likes = foundPost.Likes.filter((item)=>item.id!=likeFeedPostRb.like_id);
                        }else{
                            foundPost.Reactions = foundPost.Reactions.filter((item)=>item.id!=likeFeedPostRb.like_id);
                        }
                    }
                }

                // For liked posts
                if (foundLikedPost) {
                    if(likeFeedPostRb.Status == FeedPostLikeStatus.ACTIVE){
                        if(!foundLikedPost.Likes){
                            foundLikedPost.Likes = [];
                        }
                        if(!foundLikedPost.Reactions){
                            foundLikedPost.Reactions = [];
                        }
                        if(likeFeedPostRb.Type==ReactionType.VOTE){
                            foundLikedPost?.Likes.push(feedPostLike);
                        }else{
                            foundLikedPost.Reactions = foundLikedPost.Reactions.filter((item)=>item.id!=likeFeedPostRb.like_id);
                            foundLikedPost?.Reactions.push(feedPostLike);
                        }
                        foundLikedPost.Likes = Array.from(new Map(foundLikedPost?.Likes.map(obj => [obj.id, obj])).values());
                        foundLikedPost.Reactions = Array.from(new Map(foundLikedPost?.Reactions.map(obj => [obj.id, obj])).values());
                    } else {
                        if(likeFeedPostRb.Type==ReactionType.VOTE){
                            foundLikedPost.Likes = foundLikedPost.Likes.filter((item)=>item.id!=likeFeedPostRb.like_id);
                        }else{
                            foundLikedPost.Reactions = foundLikedPost.Reactions.filter((item)=>item.id!=likeFeedPostRb.like_id);
                        }
                    }
                }

                // For my posts
                if (foundMyPost) {
                    if(likeFeedPostRb.Status == FeedPostLikeStatus.ACTIVE){
                        if(!foundMyPost.Likes){
                            foundMyPost.Likes = [];
                        }
                        if(!foundMyPost.Reactions){
                            foundMyPost.Reactions = [];
                        }
                        if(likeFeedPostRb.Type==ReactionType.VOTE){
                            foundMyPost?.Likes.push(feedPostLike);
                        }else{
                            foundMyPost.Reactions = foundMyPost.Reactions.filter((item)=>item.id!=likeFeedPostRb.like_id);
                            foundMyPost?.Reactions.push(feedPostLike);
                        }
                        foundMyPost.Likes = Array.from(new Map(foundMyPost?.Likes.map(obj => [obj.id, obj])).values());
                        foundMyPost.Reactions = Array.from(new Map(foundMyPost?.Reactions.map(obj => [obj.id, obj])).values());
                    }else {
                        if(likeFeedPostRb.Type==ReactionType.VOTE){
                            foundMyPost.Likes = foundMyPost.Likes.filter((item)=>item.id!=likeFeedPostRb.like_id);
                        }else{
                            foundMyPost.Reactions = foundMyPost.Reactions.filter((item)=>item.id!=likeFeedPostRb.like_id);
                        }
                    }
                }
            }
        },
        addOrUpdateAndSetContestOfCurrentEvent: async function (addUpdateContestRb: AddUpdateContestRb) {
            try {
                const useClubStore = clubStore();
                const response = await this.addOrUpdateAContest(addUpdateContestRb);
                if (response) {
                    const contest = response as Contest;
                    const club = useClubStore.getClubByClubId(addUpdateContestRb.club_id);
                    useClubStore.addContestIntoClub(addUpdateContestRb.club_id, contest);
                    return contest;
                }
            } catch (e) {
                console.log(e)
            }
        },
        loadAndSetCurrentContestPositions: async function (contestId: number) {
            try {
                const response = await this.loadContestPositions(contestId);
                if (response) {
                    this.clearContestPositions();
                    for (const position of response) {
                        const tmpPosition = position as ContestPosition;
                        this.currentContestPositions.push(tmpPosition);
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        loadAndSetMyLikedPostsOfCurrentContest: async function (getMyLikedPostsRb: GetMyLikedPostsRb, clearPreviousData:boolean) {
            try {
                if(clearPreviousData){
                    this.clearMyLikedPosts();
                }
                const response = await this.loadMyLikedPosts(getMyLikedPostsRb);
                if (response) {
                    for (const rawPost of response) {
                        const tmpPost = rawPost as FeedPost;
                        this.myLikedPostsOfContestEvent.push(tmpPost);
                    }
                    return response;
                }
            } catch (e) {
                console.log(e)
            }
        },
        loadAndSetMyPostsForCurrentContest: async function (loadMyPostsForContestRb: LoadMyPostsForContestRb, clearPreviousData:boolean) {
            try {
                if(clearPreviousData){
                    this.clearMyPosts();
                }
                const response = await this.loadMyPostsForContest(loadMyPostsForContestRb);
                if (response) {
                    for (const rawPost of response) {
                        const tmpPost = rawPost as FeedPost;
                        this.myPostsForContestEvent.push(tmpPost);
                    }
                    this.checkForPendingPosts();
                    return response;
                }
            } catch (e) {
                console.log(e)
            }
        },
        loadAndSetTopPostsByRankOfAnEvent: async function (getTopPostsByRankRb: GetTopPostsByRankRb) {
            try {
                const response = await this.loadTopPostsByRank(getTopPostsByRankRb);
                if (response) {
                    this.clearTopPosts();
                    for (const tmpPost of response) {
                        const post = tmpPost as FeedPost;
                        this.topPostsOfContestEvent.push(post);
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        addPendingPosts:  function (post: FeedPost) {
            this.activePostsOfCurrentEvent.unshift(post);
            this.pendingPostOfContestEvent.unshift(post);
            this.myPostsForContestEvent.unshift(post);
        },
        checkForPendingPosts: function () {
            if(this.pendingPostOfContestEvent.length > 0) {
                for(const post of this.pendingPostOfContestEvent) {
                    const foundItem = this.activePostsOfCurrentEvent.find(item =>item.id == post.id);
                    const foundItemOnMyPost = this.myPostsForContestEvent.find(item =>item.id == post.id);
                    if(!foundItemOnMyPost || !foundItem){
                        this.myPostsForContestEvent.unshift(post);
                        this.activePostsOfCurrentEvent.unshift(post);
                    }else {
                        this.pendingPostOfContestEvent = this.pendingPostOfContestEvent.filter(item => item.id != post.id)
                    }
                }
            }
        },
        removePendingPost: function (id: number, removeFromActiveList:boolean = false) {
            if(removeFromActiveList){
                this.activePostsOfCurrentEvent = this.activePostsOfCurrentEvent.filter(item => item.id != id)
                this.myPostsForContestEvent = this.myPostsForContestEvent.filter(item => item.id != id)
            }
            this.pendingPostOfContestEvent = this.pendingPostOfContestEvent.filter(item => item.id != id)
        },
        getTotalCountOfPostsAndLikes: async function (contestId: number) {
            try {
                const config = useRuntimeConfig()
                let url = '/api/contest/get-total-count-of-feed-posts-&-likes';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {contest_id: contestId}, null, "addOrUpdateAContest" + new Date().getMilliseconds() + "_", false, true)
                if (IsOperationSuccessful) {
                    this.feedPostCount = Result['postCount'] ?? 0
                    this.totalFeedPostCount = Result['totalPostCount'] ?? 0
                    this.voteCount = Result['voteCount'] ?? 0
                    this.views = Result['views'] ?? 0
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        addUpdateComment: async function (addUpdateCommentRb: AddUpdateCommentRb) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Contest/addUpdateComment';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, addUpdateCommentRb, null, "addUpdateComment" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        sendNewContestEmail: async function (emailbody:any) {
            try {
                const useUserStore = userStore()
                const config = useRuntimeConfig()
                emailbody = { ...emailbody, user_id: useUserStore.getLoggedInUser.id, platform: config.public.NUXT_PUBLIC_SITE_URL, action: 'New Contest' }

                let url = config.public.BASE_URL_SOPS + '/api/Contest/newContestEmail';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, emailbody, null, "sendNewContestEmail" + new Date().getMilliseconds() + "_")
                if (IsOperationSuccessful) {
                    return IsOperationSuccessful;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },

        addUpdatePostCount(positive: boolean, negative: boolean) {
            if (positive) {
                this.feedPostCount += 1;
                this.totalFeedPostCount += 1;
            }
            if (negative) {
                this.feedPostCount -= 1;
                this.totalFeedPostCount -= 1;
            }
        },
        addUpdateVoteCount(positive: boolean, negative: boolean) {
            if (positive) this.voteCount += 1;
            if (negative) this.voteCount -= 1;
        },
        setCurrentContest(contest: Contest){
            this.currentContest = contest
        },
        setCurrentContestStatus(contestStatus: ContestStatusLocal){
            this.currentContestStatus = contestStatus
        },
        increaseContestViews: async function (contestId: number) {
            try {
                const config = useRuntimeConfig()
                let url = '/api/contest/increase-views';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {contest_id: contestId}, null, "increaseContestViews" + new Date().getMilliseconds() + "_", false, true)
                if (IsOperationSuccessful) {
                    // this.views = Result['Views'] ?? 0;
                    return IsOperationSuccessful;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
    },
    getters: {
        getActivePostsOfCurrentEvent: state => state.activePostsOfCurrentEvent,
        getPendingPostsOfCurrentEvent: state => state.allPostsOfCurrentEvent.filter((post) => post.Status == FeedPostStatus.PENDING),
        getAllPostsOfCurrentEvent: state => state.allPostsOfCurrentEvent,
        getCurrentContestPositions: state => state.currentContestPositions.sort((a, b) => a.Rank > b.Rank ? 1 : -1),
        getFeedPostCount: state => state.feedPostCount,
        getTotalFeedPostCount: state => state.totalFeedPostCount,
        totalVoteCount: state => state.voteCount,
        getViewsCount: state => state.views,
        getTopPostsByRank: state => state.topPostsOfContestEvent,
        getCurrentContest: state => state.currentContest,
        getCurrentContestStatus: state => state.currentContestStatus,
        getMyLikedPostsOfContestEvent: state => state.myLikedPostsOfContestEvent,
        getMyPostsForContestEvent: state => state.myPostsForContestEvent,
    },
})
